import React from 'react';
import CommentCard from '../components/commentpage/CommentCard';

function CommentPage() {
  return (
    <CommentCard />
  );
}

export default CommentPage;
