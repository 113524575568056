import React from 'react';
import ArchiveCard from '../components/archivepage/ArchiveCard';

function ArchivePage() {
  return (
    <ArchiveCard />
  );
}

export default ArchivePage;
