import React from 'react';
import HomeCard from '../components/homepage/HomeCard';

function HomePage() {
  return (
    <HomeCard />
  );
}

export default HomePage;
